import api from "@/axios";

export default class DashboardService {

    getDashVendas() {
        return api.get('api/ssdashvendas' ).then((res) => res.data);
    }



}
