<template>
  <div class="layout-dashboard">
    <div class="grid">

      <div class="col-12 md:col-12 lg:col-12" v-if='totalPedElab > 0 && escondeAvisoPedElab === false'>

          <div class="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
              <div class="font-bold">🔔 Existem {{ totalPedElab }} pedidos em elaboração </div>
              <div class="align-items-center hidden lg:flex">
                  <span class="line-height-3">, favor finalizar a integração! 🔔</span>
              </div>
              <a class="flex align-items-center ml-8" @click="$router.push('/pedidos')"  href="javascript:void(0);" >
                  <a class="text-white"><span class="underline font-bold">Abrir Pedidos</span></a>
              </a>
              <a @click='escondeAvisoPedElab = true' v-ripple class="flex align-items-center no-underline justify-content-center border-circle text-gray-50 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150 p-ripple" style="width:2rem; height: 2rem">
                  <i class="pi pi-times"></i>
              </a>
          </div>

      </div>

      <div class="col-12 md:col-6 lg:col-3" @click="$router.push('/clientes')">
        <div class="card p-0 widget-overview-box widget-overview-box-1">
          <div class="flex align-items-center justify-content-start pt-3 pb-2 px-4">
            <i class="pi pi-chart-line text-6xl"></i>

            <div class="pl-3 overflow-hidden">
              <span class="header block white-space-nowrap overflow-hidden">META FATURAMENTO</span>
              <span class="block text-4xl font-bold" :style="{'color': ($appState.colorScheme === 'light' ? '#035388' : '#1992D4')}">R$ {{ formatInteiro(widgetsProtheus.meta) }}</span>
            </div>
          </div>
          <img
              :src="'layout/images/dashboard/users' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
              class="w-full" alt="users">
        </div>
      </div>

      <div class="col-12 md:col-6 lg:col-3">
        <div class="card p-0 widget-overview-box widget-overview-box-2">
          <div class="flex align-items-center justify-content-start pt-3 pb-2 px-4">
            <i class="pi pi-briefcase text-6xl"></i>
            <div class="pl-3 overflow-hidden">
              <span class="header block white-space-nowrap overflow-hidden">FATURADO MÊS</span>
              <span class="block text-4xl font-bold" :style="{'color': ($appState.colorScheme === 'light' ? '#CB6E17' : '#DE911D')}">R$ {{ formatDecimal(widgetsProtheus.faturado) }}</span>
            </div>
          </div>
          <img
              :src="'layout/images/dashboard/locations' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
              class="w-full" alt="users">
        </div>
      </div>

      <div class="col-12 md:col-6 lg:col-3"
           @click="$router.push('/vendas')">
        <div class="card p-0 widget-overview-box widget-overview-box-3">
          <div class="flex align-items-center justify-content-start pt-3 pb-2 px-4">
            <i class="pi pi-shopping-cart text-6xl"></i>
            <div class="pl-3 overflow-hidden">
              <span class="header block white-space-nowrap overflow-hidden">Pedidos em Aberto ({{ formatInteiro(widgetsProtheus.pedidos) }})</span>
              <span class="block text-4xl font-bold" :style="{'color': ($appState.colorScheme === 'light' ? '#147D64' : '#27AB83')}">R$ {{ formatDecimal(widgetsProtheus.vlrped) }}</span>
            </div>
          </div>
          <img
              :src="'layout/images/dashboard/rate' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
              class="w-full" alt="users">
        </div>
      </div>

      <div class="col-12 md:col-6 lg:col-3"
           @click="$router.push('/leads')">
        <div class="card p-0 widget-overview-box widget-overview-box-4 md:h-full md:relative">
          <div class="flex align-items-center justify-content-start pt-3 pb-2 px-4">
            <i class="pi pi-money-bill text-6xl"></i>
            <div class="pl-3 overflow-hidden">
              <span class="header block white-space-nowrap overflow-hidden">COMISSÃO MÊS</span>
              <span class="block text-4xl font-bold" :style="{'color': ($appState.colorScheme === 'light' ? '#6551B7' : '#A896FF')}">R$ {{ formatDecimal(widgetsProtheus.comissao) }}</span>
            </div>
          </div>
          <img :src="'layout/images/dashboard/interactions' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
               class="w-full md:absolute md:bottom-0" alt="users">
        </div>
      </div>


      <div class="col-12 md:col-12 lg:col-12" v-if="loadingData">
        <div  class="custom-skeleton p-4" >
          <Skeleton width="100%" height="50px"></Skeleton>
        </div>
      </div>


      <div class="col-12 lg:col-6">
        <div class="card widget-overview-chart h-full">
          <div class="card-header">
            <span class="font-bold">Pedidos Semana</span>
<!--            <Dropdown :options="overviewWeek" v-model="selectedOverviewWeek" optionLabel="name" @change="changeOverviewWeek($event)"></Dropdown>-->
          </div>

          <div class="graph mb-3 mt-3">
            <Chart ref="overview" type="bar" :data="pedSemanaChart" :options="pedSemanaChartOptions" id="overview-chart"></Chart>
          </div>

          <div class="graph-footer flex align-items-center justify-content-end">
            <Button type="button" label="Faturado" class="p-button-outlined p-button-rounded p-button-sm font-bold mr-3 py-1 first-data-button" @click="changePedSemanaDataSet('FA')"></Button>
            <Button type="button" label="Elaboração" class="p-button-outlined p-button-rounded p-button-sm font-bold mr-3 py-1 first-data-button" @click="changePedSemanaDataSet('EL')"></Button>
            <Button type="button" label="Confirmado" class="p-button-outlined p-button-rounded p-button-sm font-bold mr-3 py-1 first-data-button" @click="changePedSemanaDataSet('CO')"></Button>
            <Button type="button" label="Todos" class="p-button-outlined p-button-rounded p-button-sm font-bold mr-3 py-1 second-data-button" @click="changePedSemanaDataSet('TO')"></Button>
          </div>
        </div>
      </div>



        <div class="col-12 md:col-6 lg:col-3">

            <div class="card">
                <div class="card-header mb-4">
                    <span class="font-bold">Últimos Pedidos (Elaboração)</span>

                </div>

                <DataTable :value="ultimosPedidos2" :rows="7" responsiveLayout="scroll">

                    <Column field="id" header="ID" :sortable="false">
                        <template #body="slotProps">
                            {{slotProps.data.id}}
                        </template>
                    </Column>

                    <Column field="nome" header="Nome" :sortable="false" >
                        <template #body="slotProps">
                            {{slotProps.data.nome}}
                        </template>
                    </Column>
                    <Column field="price" header="Valor(R$)" :sortable="false">
                        <template #body="slotProps">
                            {{formatDecimal(slotProps.data.valor)}}
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>


<!--      <div class="col-12 md:col-6 lg:col-3">-->
<!--        <div class="card p-0 widget-people h-full relative">-->
<!--          <div class="header font-bold py-4 px-3">Últimos Pedidos (Status Elaboração)</div>-->
<!--          <div class="overflow-y-auto" style="max-height: calc(100% - 100px)">-->

<!--            <div v-for="pedido of ultimosPedidos" :key="pedido.id"-->
<!--                class="mb-4 px-3 flex align-items-center justify-content-start">-->
<!--              <Avatar :label="pedido.estado" size="large" shape="circle" class="text-base font-bold"-->
<!--                      :style="{'background-color':'rgba(101, 214, 173, 0.1)', 'color': '#27AB83', 'border': '1px solid #65D6AD'}"></Avatar>-->
<!--              <div class="ml-3">-->
<!--                <span class="name block">{{ pedido.nome }}</span>-->
<!--                <span class="position block">R$ {{ formatDecimal(pedido.valor) }}</span>-->
<!--              </div>-->
<!--            </div>-->


<!--          </div>-->
<!--          <div @click="$router.push('/pedidos')"-->
<!--               class="footer cursor-pointer flex align-items-center justify-content-center p-3 absolute bottom-0 w-full">-->
<!--            <span class="font-bold mr-2">Mais detalhes</span>-->
<!--            <i class="pi pi-chevron-right"></i>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="col-12 md:col-6 lg:col-3">
        <div class="card widget-profile-completion">
          <div class="flex flex-column align-items-center justify-content-center mt-4">
            <Chart type="doughnut" :data="atendChart" :options="atendChartOptions" style="position:relative; width: 50%" class="m-auto mb-5"></Chart>
            <span class="font-bold mb-2">Consumo de Carteira (Mês)</span>
            <span class="font-bold text-6xl mb-2">{{ formatDecimal(percAtendimento) }}%</span>
            <div class="flex align-items-center justify-content-center">
              <div>
                <i class="pi pi-circle-on" style="color:#1992D4"></i>
                <span class="subtext ml-2">Atendido</span>
              </div>
              <div class="ml-3">
                <i class="pi pi-circle-on" style="color:#3E4C59"></i>
                <span class="subtext ml-2">Ocioso</span>
              </div>
              <div class="ml-3">
                <i class="pi pi-circle-on" style="color:#E4E7EB"></i>
                <span class="subtext ml-2">Elaboração</span>
              </div>
            </div>
          </div>
          <div class="mt-4 flex align-items-center justify-content-start">
            <Avatar icon="pi pi-user-edit" size="large" shape="circle" class="text-base font-bold"
                    :style="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></Avatar>
            <div class="ml-3">
              <span class="label block">Últimos Clientes</span>
              <span class="action block font-bold" @click="$router.push('/clientes')">Abrir todos</span>
            </div>
          </div>
          <div class="mt-4 flex align-items-center justify-content-start">
            <Avatar icon="pi pi-send" size="large" shape="circle" class="text-base font-bold"
                    :style="{'background-color':'rgba(250, 219, 95, 0.1)', 'color': '#DE911D', 'border': '1px solid #FADB5F'}"></Avatar>
            <div class="ml-3">
              <span class="label block">Últimos Leads</span>
              <span class="action block font-bold" @click="$router.push('/leads')">Abrir todos</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 md:col-6 lg:col-6" @click="$router.push('/leads')">
        <div class="card widget-timeline">
          <div class="card-header font-bold mb-4">Últimos Clientes</div>
          <div class="flex mb-1 p-2 pb-0 shadow-1" style="border-radius: 5px"
               v-for="cliente of ultimosClientes" :key="cliente.id">
            <div class="flex flex-column align-items-center">
              <span
                  class="flex align-items-center justify-content-center border-circle p-2 timeline-icon timeline-icon-3">
                <i class="pi pi-user"></i>
              </span>
              <div class="h-full timeline-line-3" style="width: 2px; min-height: 1rem"></div>
            </div>
            <div class="ml-3 w-full">
              <div class="flex align-items-center justify-content-between timeline-event-header mb-3 mt-2">
                <span><span class="font-bold">{{ cliente.nome }}</span><p></p> {{ cliente.cidade }} - {{ cliente.estado }}</span>
                <span class="font-bold subtext">{{ formatDateTime(cliente.created_at) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="card widget-graph p-5">
          <div class="card-header font-bold mb-5">Evolução Pedidos Semestre (R$)</div>

          <Chart type="line" :data="chartSemestreData" :options="chartSemestreOptions" id="nasdaq-chart"></Chart>
        </div>
      </div>



      <div class="col-12 md:col-6 lg:col-4" >
        <div class="card widget-image-stats relative">
          <div class="card-header">
            <span class="font-bold">Produtos em Destaque</span>
<!--            <div class="header-right-section flex align-items-center">-->
<!--              <Button type="button" icon="pi pi-angle-left"-->
<!--                      class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-2 p-2"></Button>-->
<!--              <Button type="button" icon="pi pi-angle-right"-->
<!--                      class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-3 p-2"></Button>-->
<!--              <span class="font-bold">1</span><span>/5</span>-->
<!--            </div>-->
          </div>
          <img src="layout/images/bg-product-novo-2.jpg" alt="product" class="w-full my-4">

          <div v-for="grupoProd of maioresProdutos" :key="grupoProd.grupo">
            <div class="flex align-items-center justify-content-between mb-2">
              <span class="font-bold">Grupo: {{ grupoProd.grupo }}</span>
              <span>R$ {{ formatDecimal(grupoProd.valor) }}</span>
            </div>
            <ProgressBar :value="grupoProd.valor" :showValue="false" class="green-bar mb-4" max="100000"></ProgressBar>
          </div>

        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4" >
        <div class="card widget-tasks">
          <div class="card-header block mb-3 px-2">
            <span class="block font-bold mb-3">Material de apoio</span>
            <span class="block font-bold text-2xl mb-4">Website Nesher</span>
            <span class="badge block w-min font-bold mb-4 px-2 py-1 border-1 border-solid">Marketing</span>
            <span class="clock"><i class="pi pi-clock mr-1"></i>Dez 15 . <span
                class="font-bold">40 dias</span></span>
          </div>
          <a href="https://moveisnesher.com.br/" target="_blank">
          <p class="px-2 mb-8">Clique aqui e acesse  </p>
          </a>
          <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
            <div class="footer-left flex align-items-center">
              <Button type="button" icon="pi pi-angle-left"
                      class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-2 p-2 w-2rem h-2rem"></Button>
              <Button type="button" icon="pi pi-angle-right"
                      class="p-button-outlined p-button-rounded p-button-plain p-button-sm font-bold mr-3 p-2 w-2rem h-2rem"></Button>
              <span class="font-bold">4</span><span>/ 52</span>
            </div>
            <div class="footer-right">
              <AvatarGroup class="w-9rem justify-content-evenly">
                <Avatar image="demo/images/avatar/amyelsner.png" shape="circle"></Avatar>
                <Avatar image="demo/images/avatar/asiyajavayant.png" shape="circle"></Avatar>
                <Avatar image="demo/images/avatar/onyamalimba.png" shape="circle"></Avatar>
                <Avatar image="demo/images/avatar/ionibowcher.png" shape="circle"></Avatar>
                <Avatar image="demo/images/avatar/xuxuefeng.png" shape="circle"></Avatar>
              </AvatarGroup>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4">
        <div class="card widget-ratings">
          <div class="card-header font-bold mb-4">Metas</div>
          <TabView orientation="left">


            <TabPanel header="Produtos">
              <table class="w-full">
                <tbody>


                <tr v-for="meta of ultimasMetas" :key="meta.id">
                  <td class="w-4">{{ meta.codexterno }}</td>
                  <td class="w-1"><span class="font-bold">{{ formatDecimal(meta.meta) }}</span></td>

                  <td class="w-2" v-if="meta.realizado > meta.meta">
                    <div class="flex align-items-center justify-content-between px-2 py-1 success-badge">
                      <i class="pi pi-arrow-up"></i>
                      <span class="font-bold ml-1">{{ formatDecimal(meta.realizado) }}</span>
                    </div>
                  </td>

                  <td class="w-2" v-else>
                    <div class="flex align-items-center justify-content-between px-2 py-1 danger-badge">
                      <i class="pi pi-arrow-down"></i>
                      <span class="font-bold ml-1">{{ formatDecimal(meta.realizado) }}</span>
                    </div>
                  </td>

                  <td class="w-4">
                    <div class="flex align-items-center justify-content-center high-tag p-1">
                      <span class="font-bold">ALTA PRIORIDADE</span>
                    </div>
                  </td>
                </tr>


                </tbody>
              </table>
            </TabPanel>


            <TabPanel header="Outros">
              <table class="w-full">
                <tbody>
<!--                <tr>-->
<!--                  <td class="w-4">RS</td>-->
<!--                  <td class="w-1"><span class="font-bold">2.8</span></td>-->
<!--                  <td class="w-2">-->
<!--                    <div-->
<!--                        class="flex align-items-center justify-content-between px-2 py-1 warning-badge">-->
<!--                      <i class="pi pi-circle-off"></i>-->
<!--                      <span class="font-bold ml-1">0.9</span>-->
<!--                    </div>-->
<!--                  </td>-->
<!--                  <td class="w-4">-->
<!--                    <div class="flex align-items-center justify-content-center high-tag p-1">-->
<!--                      <span class="font-bold">ALTA PRIORIDADE</span>-->
<!--                    </div>-->
<!--                  </td>-->
<!--                </tr>-->


                </tbody>
              </table>
            </TabPanel>
          </TabView>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import DashboardService from "@/service/DashboardService";
import moment from 'moment'

export default {
  watch: {
    '$appState.colorScheme'() {
      this.pedSemanaChartOptions = this.getOrdersOptions();
      this.pedSemanaChart.datasets[1].backgroundColor[0] = this.$appState.colorScheme === 'dark' ? '#879AAF' : '#E4E7EB';
    }
  },
  created() {
    this.dashboardService = new DashboardService();

  },
  data() {
    return {
      widgetsProtheus: [],
      escondeAvisoPedElab: false,
      totalPedElab: 0,
      loadingData: true,
      ultimasMetas: [],
      maioresProdutos: [],
      percAtendimento: 0,
      ultimosPedidos: [],
      ultimosPedidos2: [],
      ultimosClientes: [],
      dataSet1PedSemana: [],
      dataSet2PedSemana: [],
      dataSet3PedSemana: [],
      dataSet4PedSemana: [],
      labelsGraphSemana: [],
      dashboardService: null,
      totalClientes: 0,
      totalPedidoSemana: 0,
      totalFaturadoMes: 0,


      atendChart: null,
      atendChartOptions: null,

      pedSemanaChart: null,
      pedSemanaChartOptions: null,
      overviewWeek: [
        {name: 'Semana Passada', code: '0'},
        {name: 'Esta Semana', code: '1'}
      ],
      selectedOverviewWeek: null,
      chartSemestreData: null,
      chartSemestreOptions: null,
      chart: null
    }
  },
  mounted() {
    console.log('mounted_dashboard_32')
    // console.log(process.env.NODE_ENV)
    // console.log(process.env.VUE_APP_MY_ENV_VARIABLE)
    // console.log(process.env)

    this.getGradient();
    this.getTabela();
    this.selectedOverviewWeek = this.overviewWeek[0];
    this.pedSemanaChartOptions = this.getOrdersOptions();
  },
  methods: {
    changePedSemanaDataSet(status){
      console.log('changePedSemanaDataSet')
      console.log(status)


      switch (status){
        case 'TO':
          this.pedSemanaChart.datasets[0].data = this.dataSet1PedSemana;
          this.pedSemanaChart.datasets[1].data = this.dataSet1PedSemana;
          break
        case 'EL':
          this.pedSemanaChart.datasets[0].data = this.dataSet2PedSemana;
          this.pedSemanaChart.datasets[1].data = this.dataSet2PedSemana;
          break
        case 'CO':
          this.pedSemanaChart.datasets[0].data = this.dataSet3PedSemana;
          this.pedSemanaChart.datasets[1].data = this.dataSet3PedSemana;
          break
        case 'FA':
          this.pedSemanaChart.datasets[0].data = this.dataSet4PedSemana;
          this.pedSemanaChart.datasets[1].data = this.dataSet4PedSemana;
          break
      }
      this.$refs.overview.refresh();

    },


    getTabela(){
      this.loadingData = true
      this.dashboardService.getDashVendas().then((data) => {
        console.log('dashVendas getDash')
        // console.log(data)
        this.totalClientes = parseFloat(data[0].totalCli)
        this.totalPedidoSemana = parseFloat(data[0].totPedSemana)
        this.totalFaturadoMes = parseFloat(data[0].totFaturadoMes)


        this.ultimosPedidos = data[2]
        this.ultimosClientes = data[4]
        this.maioresProdutos = data[6]
        this.ultimasMetas = data[7]
        var pedidosSemestre = data[5]
        var pedidosSemana = data[1]

        this.totalPedElab = data[8]

        this.maioresProdutos.forEach((grupoProd) => {
          grupoProd.valor = parseFloat(grupoProd.valor)
        })

          // console.log('widgetsProdutos')
          // console.log(data[9])
          if (data[9] != null){
              this.widgetsProtheus = data[9]
          }


          console.log('ultpeds2')
          console.log(data[2])
          this.ultimosPedidos2 = data[2]


        pedidosSemana.forEach((pedidoDia) => {
          // console.log('pedidoDia')
          // console.log(pedidoDia)

          var diaSemana = ''
          switch (pedidoDia.diasemana) {
            case 1:
              diaSemana = 'D'
              break
            case 2:
              diaSemana = 'S'
              break
            case 3:
              diaSemana = 'T'
              break
            case 4:
              diaSemana = 'Q'
              break
            case 5:
              diaSemana = 'Q'
              break
            case 6:
              diaSemana = 'S'
              break
            case 7:
              diaSemana = 'S'
              break
          }
          this.labelsGraphSemana.push(diaSemana)
          this.dataSet1PedSemana.push(pedidoDia.valor)

          switch (pedidoDia.status){
            case 'EL':
              this.dataSet2PedSemana.push(pedidoDia.valor)
              break
            case 'CO':
              this.dataSet3PedSemana.push(pedidoDia.valor)
              break
            case 'FA':
              this.dataSet4PedSemana.push(pedidoDia.valor)
              break
          }
        })

        // console.log('labelsGraphSemana')
        // console.log(this.labelsGraphSemana)

        this.pedSemanaChart = {
          labels: this.labelsGraphSemana,
              datasets: [
                {
                  data: this.dataSet1PedSemana,
                  borderColor: ['#FADB5F',],
                  pointBorderColor: 'transparent',
                  pointBackgroundColor: 'transparent',
                  type: 'line',
                  fill: false,
                },
                {
                  data: this.dataSet1PedSemana,
                  backgroundColor: [this.$appState.colorScheme === 'dark' ? '#879AAF' : '#E4E7EB'] ,
                  fill: true,
                  borderRadius: 10,
                  borderSkipped: 'top bottom',
                  barPercentage: 0.3
                },
              ]
        }

        this.percAtendimento = data[3].totalCliPedMes / data[3].totalOcioso
        this.atendChart = {
          labels: ['Atendido','Ocioso',],
          datasets: [{
            data:  [data[3].totalCliPedMes, data[3].totalOcioso, data[3].totalCliElabora],
            backgroundColor: [  '#1992D4', '#3E4C59', '#E4E7EB'],
            borderWidth: 0,
          }]
        }

        this.atendChartOptions = {
          plugins: {
            legend: {
              display: false,
            }
          },
          responsive: true,
              cutout: 60
        }


        let labelsSemestre = []
        let valoresSemestre = []
        pedidosSemestre.forEach((pedidoSem) => {
          // console.log('pedidoSem')
          // console.log(pedidoSem)
          let mesEscr = ''
          switch (pedidoSem.mes) {
            case 1:
              mesEscr = 'JAN'
              break
            case 2:
              mesEscr = 'FEV'
              break
            case 3:
              mesEscr = 'MAR'
              break
            case 4:
              mesEscr = 'ABR'
              break
            case 5:
              mesEscr = 'MAI'
              break
            case 6:
              mesEscr = 'JUN'
              break
            case 7:
              mesEscr = 'JUL'
              break
            case 8:
              mesEscr = 'AGO'
              break
            case 9:
              mesEscr = 'SET'
              break
            case 10:
              mesEscr = 'OUT'
              break
            case 11:
              mesEscr = 'NOV'
              break
            case 12:
              mesEscr = 'DEZ'
              break
          }

          labelsSemestre.push(mesEscr)
          valoresSemestre.push(pedidoSem.valor)

        })
        // console.log('labelsSemestre')
        // console.log(labelsSemestre)


        this.chartSemestreData = {
          labels: labelsSemestre,
          datasets: [{
                data: valoresSemestre,
                backgroundColor: 'rgba(25, 146, 212, 0.2)',
                borderColor: 'rgba(25, 146, 212, 0.5)',
                pointBorderColor: 'transparent',
                pointBackgroundColor: 'transparent',
                fill: true,
                tension: .4
              }]
        }

        this.chartSemestreOptions = {
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              min: 0,
              ticks: {
                color: '#A0A7B5'
              }
            },
            x: {
              grid: {
                display: true,
              },
              ticks: {
                color: '#A0A7B5',
                    beginAtZero: true,
              }
            }
          }
        }

        this.loadingData = false

      })
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
      return 0.00;
    },

    formatInteiro(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 0});
      return 0;
    },


    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return 0.00;
    },

    formatDateTime(value) {
      // console.log('value formatDate')
      // console.log(value)
      // console.log(moment())
      const format = "DD/MM/YYYY HH:mm:ss"
      var date = new Date(value);
      let dateTime = moment(date).format(format);
      // console.log(dateTime1)
      return dateTime
    },


    getGradient() {
      this.chart = document.getElementsByTagName('canvas')[1].getContext('2d');
      const gradientStroke = this.chart.createLinearGradient(100, 0, 1150, 100);
      gradientStroke.addColorStop(0, 'rgba(21, 184, 194, 0)');
      gradientStroke.addColorStop(0.5, 'rgba(25, 146, 212, 1)');
      gradientStroke.addColorStop(1, 'rgba(23, 88, 124, 1)');

      const gradientFill = this.chart.createLinearGradient(0, 0, 1150, 0);
      gradientFill.addColorStop(1, 'rgba(25, 146, 212, 0.34)');
      gradientFill.addColorStop(0, 'rgba(232, 247, 255, 0.34)');

      // this.chartSemestreData.datasets[0].borderColor = gradientStroke;
      // this.chartSemestreData.datasets[1].borderColor = gradientStroke;
      // this.chartSemestreData.datasets[2].borderColor = gradientStroke;

      // this.chartSemestreData.datasets[2].backgroundColor = gradientFill;
    },

    getOrdersOptions() {
      return {
        plugins: {
          legend: {
            display: false
          }
        },
        responsive: true,
        hover: {
          mode: 'index'
        },
        scales: {
          y: {
            min: 0,
            ticks: {
              stepSize: 0,
              callback: function(value, index) {
                if (index === 0) {
                  return value;
                }
                else {
                  return value + 'k';
                }
              },
              color: this.$appState.colorScheme === 'dark' ? '#DBE2EB' : '#3E4C59'
            },
            grid: {
              borderDash: [2, 2],
              color: this.$appState.colorScheme === 'dark' ? '#4E657F' : '#E4E7EB',
              drawBorder: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              color: this.$appState.colorScheme === 'dark' ? '#DBE2EB' : '#3E4C59'
            }
          }
        }
      };
    },
  }
}
</script>

<style scoped>

</style>
